import React from 'react';
import { Heading } from '../Heading/Heading';
import './fold.scss';
import classNames from 'classnames';
import { ArrowRight } from 'react-feather';

const Fold = ({ title, description, btnText, btnLink, centered, small }) => {
  return (
    <div className={classNames("fold", {
      "fold--centered": centered,
      "fold--small": small,
    })}>
      <div className="container">
        <div className="gr-12 no-gutter@md">
          <div className="fold__main-content">
            <div className="fold__title-holder">
              <Heading
                level="h1"
                revealAnimation
              >
                {title}
              </Heading>
            </div>
            <div className="fold__content">
              <p className="intro">
                {description}
              </p>
              {btnText &&
                <a href={btnLink}
                  className="btn btn--secondary btn--with_icon"
                >
                  <span className="btn__text">{btnText}</span>
                  <div className="btn__icon">
                    <ArrowRight size="24" />
                  </div>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};

export default Fold;
