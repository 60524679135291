import React from 'react';
import './work-card-inner.scss';
import { Heading } from '../Heading/Heading';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Tag } from '../Tag/Tag';


export const WorkCardInner = ({ title, category, description, image, tags, id }) => {
    const parsedImage = getImage(image);


    return (
        <div className="work-card-inner" id={id}>
            <div className="work-card-inner__holder">
                <div className="work-card-inner__details">
                    <Heading level="h2">{title}</Heading>
                    <p className="category">{category}</p>
                    <div className="tags">
                        {tags.map((tag, index) =>
                            <Tag key={tag}>{tag}</Tag>
                        )}
                    </div>
                    <p className="description">{description}</p>
                </div>
                <div
                    className="work-card-inner__image"
                >
                    <GatsbyImage
                        image={parsedImage}
                        className="rspimg project-img"
                        alt={title}
                    />
                </div>
            </div>
        </div >
    );
};